/** include framework dependencies **/

@import './../../framework/_Factories';
@import './../../framework/_AbstractClasses';
@import './../../framework/_Constants';

/** end include dependencies **/

/** include sections **/

$guideTitle:#0f1d41;

@import './../../sections/general/header';
@import './../../sections/general/page_header';
@import './../../sections/home/_news';

/** end include sections **/

$nameColor:#58585a;
$titleColor:#0c1e3f;
$subtitleColor:#4e5157;
$descriptionColor:#4e5157;


$buttonTextColor:#fff;
$buttonTextBackground:#0f1d41;
$buttonTextBorderColor:#040a1a;

$buttonIconColor:#fff;
$buttonIconBackground:#ce1e29;
$buttonIconBorderColor:#93161e;

$redFirst:#e0313c;
$redLast:#ff414f;

$bleumarineFirst:#153f8c;
$bleumarineLast:#1c53b8;

$blueFirst:#1e66e9;
$blueLast:#2885ff;


/** start board members list section **/
.board-members-list{
	.board-member{
		margin-bottom: 50px;
		.info-left{
			@include rectangle(165px, 210px);
			margin-top:-60px;
			.image-container{
				@include square(165px);
				top:0;
				left:0;
			}
			.three-rhombus{
				@include rectangle(165px, 70px);
				bottom:0;
				.red{
					@extend .main-rhombus;
					@include TwoColorsGradient(left, to right, $redFirst, $redLast);
					@include square(34px);
					left:42px;
					bottom:28px;
				}
				.bleumarine{
					@extend .main-rhombus;
					@include TwoColorsGradient(left, to right, $bleumarineFirst, $bleumarineLast);
					@include square(34px);
					left:66px;
					bottom:4px;
					top:auto;
				}
				.blue{
					@extend .main-rhombus;
					@include square(34px);
					@include TwoColorsGradient(left, to right, $blueFirst, $blueLast);
					right:41px;
					bottom:28px;
				}
			}
		}

		.info-right{
			padding-left:$fullPadd;
			h3{
				margin-bottom:0px;
				margin-top:0;
				a{
					@include textDecorator($titleColor, $latobold, 20px, 0 0 0 0);
					@include LinkDecorationNone();
					line-height:25px;
				}
			}
			.name{
				@include textDecorator($nameColor, $latobold, 20px, 0 0 0 0);
				@include LinkDecorationNone();
				line-height:25px;
			}
			.subtitle{
				@include textDecorator($subtitleColor, $latoregular, 14px, 0 0 $halfMargin 0);
			}
			.description{
				@include textDecorator($descriptionColor, $latoregular, 14px, 0 0 $halfMargin 0);
			}
			.site-button{
				@extend .SiteButtonAbstract;
				.text{
					@include ColorFactory($buttonTextColor, $buttonTextBackground, $buttonTextBorderColor);
				}
				.icon{
					@include ColorFactory($buttonIconColor, $buttonIconBackground, $buttonIconBorderColor);
					background:$buttonIconBackground url(#{$homeUrl}right_arrow.png) 16px 14px no-repeat;

				}
				clear:both;
			}
		}
	}
}

/** end board members list section **/

/** start interviews section **/

$interviewsTitle:#0f1d41;
$interviewsSubtitle:#4e5157;
$interviewsSubBackground:#ce1e29;
.interviews-block{
	.section-header{
		h2{
			text-align:center;
			width:100%;
			a{
				@include textDecorator($interviewsTitle, $latobold, 36px, 0 0 $halfMargin 0);
				@include LinkDecorationNone();
				line-height: 45px;
				text-transform:uppercase;
			}
		}
		.subtitle{
			@include textDecorator($interviewsSubtitle, $latobold, 20px, 0 0 $halfMargin 0);
			line-height:25px;
			text-transform:uppercase;
			text-align:center;
			width:100%;
		}
	}

	.interviews-list{
		.interview{
			padding:0 $halfPadd;
			.news-element{
				@include rectangle(260px, 140px);
				margin-left:0;
				.header{
					.image-container{
						top:-51px;
						z-index:2;
					}
					.title-container{
						@include rectangle(210px, 52px);
						width:210px;
						margin-left:52px;
						background:$mainHeaderBackground;
						z-index:1;
						padding-left:52px;
						h3{
							@include textDecorator(white, $latobold, 12px, 0 0 20px 0);
							line-height:16px;
							padding-top:8px;
							text-align:left;
							height:auto;
						}
					}
					.subtitle-container{
						@include rectangle(180px, 25px);
						margin-left:52px;
						clear:both;
						.triangle-corner{
							@include triangle($interviewsSubBackground, 25px, 25px, 'top-right', 'isosceles');
						}
						.date-container{
							background:$interviewsSubBackground;
							padding:3px 0 0 26px;
							@include rectangle(155px, 25px);
							p{
								@include textDecorator(white, $latomedium, 12px, 0 0 20px 0);

							}
						}
					}

				}

			}
		}
	}
}

/** end interviews section **/

@media(max-width:1023px){
	.centered, .row{
		width:100%;
	}
}

@media(max-width:1023px){
	.section-header{
		padding:0 $halfPadd;
	}
	.board-members-list{
		.board-member {
			margin-bottom:100px;
			.info-left {
				@include CenterContainer();
				margin-bottom: $fullMargin;
			}
			.info-right{
				padding: 0 $halfPadd;
				.name, .title, .subtitle, .description{
					width:100%;
					text-align:center;
					display:block;
					margin-bottom:5px;
				}
				.description{
					margin-bottom: 15px;
				}
				.site-button{
					@include CenterContainer();
				}
			}
		}
	}
	.interviews-block{
		.interviews-list {
			.interview {
				.news-element {
					@include CenterContainer();
					margin-bottom:40px;
				}
			}
		}
	}
}
@media(max-width:767px){


}