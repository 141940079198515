/** news section **/
$newsTitle:#0f1d41;
$newsContent:#79797a;
$mainHeaderBackground:#0d1a3a;
$dateContainerBackground:#ce1e29;


$buttonTextColor:white;
$buttonTextBackground:#d7222d;
$buttonTextBorderColor:#971820;

$buttonIconColor:white;
$buttonIconBackground:#d5212c;
$buttonIconBorderColor:#90151d;


$subtitleColor:#ce1e29;

.news{
	.title{
		text-align:center;
		height:105px;
		padding-top:60px;
		h3{
			@include textDecorator($guideTitle, $latobold, 36px, 0 0 20px 0);
			line-height:40px;
			text-transform:uppercase;
		}
	}
	.main-news-container{
		.image-container{
			z-index:2;
		}
		.header{
			z-index:1;
			height:90px;
			background:$mainHeaderBackground;
			margin-top:60px;
			.title-container{
				padding-left:195px;
				height:65px;
				h3{
					@include textDecorator(white, $latomedium, 20px, 0 0 20px 0);
					line-height:23px;
					padding-top:8px;
					text-align:left;

				}
			}
			.date-container{
				background:$dateContainerBackground;
				height:25px;
				padding-top:4px;
				padding-left:195px;
				p{
					@include textDecorator(white, $latomedium, 12px, 0 0 20px 0);
					line-height:14px;
				}
			}
		}
		.content{
			padding:15px 15px 0 195px;
			p{
				@include textDecorator($newsContent, $latoregular, 14px, 0 0 30px 0);
				line-height:17px;
			}
		}
		.site-button{
			@extend .SiteButtonAbstract;
			.text{
				@include ColorFactory($buttonTextColor, $buttonTextBackground, $buttonTextBorderColor);
			}
			.icon{
				@include ColorFactory($buttonIconColor, $buttonIconBackground, $buttonIconBorderColor);
				background:$buttonIconBackground url(#{$homeUrl}nearshoring_icon.png) 11px 9px no-repeat;
			}
		}
	}
	.side-news-container{
		margin-top:50px;
	}
	.news-element{
		@include rectangle(260px, 140px);
		margin:auto auto 25px auto;
		float:right;
		.header{
			.image-container{
				top:-51px;
				left:-52px;
				z-index:2;
			}
			.title-container{
				@include rectangle(210px, 52px);
				width:210px;
				background:$mainHeaderBackground;
				z-index:1;
				padding-left:52px;
				h3{
					@include textDecorator(white, $latobold, 12px, 0 0 20px 0);
					line-height:16px;
					padding-top:8px;
					text-align:left;
					height:auto;
				}
			}
			.subtitle-container{
				@include rectangle(180px, 25px);
				clear:both;
				.triangle-corner{
					@include triangle($subtitleColor, 25px, 25px, 'top-right', 'isosceles');
				}
				.date-container{
					background:$subtitleColor;
					padding:3px 0 0 26px;
					@include rectangle(155px, 25px);
					p{
						@include textDecorator(white, $latomedium, 12px, 0 0 20px 0);

					}
				}
			}

		}
		.content{
			p{
				@include textDecorator(white, $latomedium, 20px, 0 0 20px 0);
			}
		}
	}
}